var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "views_system_notice_domain_change d-flex align-center justify-center bbox",
      },
      [
        _c("div", { staticClass: "main_group" }, [
          _c("div", { staticClass: "title" }, [_vm._v("尊敬的妙行用户：")]),
          _c("div", { staticClass: "content" }, [
            _vm._v(
              "由于公司业务调整及其他平台需要，妙行网址由原来https://pmcs.ourutec.com  已变更为新网址 "
            ),
            _c(
              "a",
              {
                attrs: { target: "_blank", href: "https://pmcs.yourutec.com" },
              },
              [_vm._v("https://pmcs.yourutec.com")]
            ),
            _vm._v(
              "，请妥善保存新妙行网址，原妙行网址将于2021年12月10日弃用，妙行用户数据无影响。"
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }