import { render, staticRenderFns } from "./DomainChange.vue?vue&type=template&id=31b723dc&scoped=true"
var script = {}
import style0 from "./DomainChange.vue?vue&type=style&index=0&id=31b723dc&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31b723dc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/workspace/pmcs-web-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31b723dc')) {
      api.createRecord('31b723dc', component.options)
    } else {
      api.reload('31b723dc', component.options)
    }
    module.hot.accept("./DomainChange.vue?vue&type=template&id=31b723dc&scoped=true", function () {
      api.rerender('31b723dc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/SystemNotice/DomainChange.vue"
export default component.exports